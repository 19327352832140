<template>
	<div>
		<contentHeader title="Pemesanan Obat" url="/pemesanan-obat" subTitle="Pemesanan Obat" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
          			<h3 class="card-title col-6">Tambah Pemesanan Obat</h3>
          		</div>
          		<form @submit.prevent="prosesPembelian">
          			<div class="card-body">
	          			<div class="px-lg-2">
	          				<div class="row">
	          					<div class="col">
	          						<div class="form-group">
				                  <label for="">No. Faktur <span class="text-danger">*</span></label>
				                  <input type="text" class="form-control" v-model="pembelian.nomor_faktur" required="">
				                </div>
	          					</div>
	          					<div class="col">
	          						<div class="form-group">
					                  <label for="">Supplier <span class="text-danger">*</span></label>
									  <multiselect style="min-width: 100px;" 
								          v-model="cabang_select_value"
								          deselect-label="Can't remove this value"
								          track-by="nama"
								          label="nama"
								          placeholder="Pilih Supplier"
									      :loading="loading_cabang_select"
								          :options="cabang"
								          :allow-empty="false"
								          @update:model-value="pilihCabang"
								        >
								      </multiselect>
				                	</div>
	          					</div>
	          				</div>

		          			<table class="table table-bordered table-hover mt-2">
		          				<thead>
		          					<tr>
		          						<th scope="col col-lg-3" class="text-sm">NAMA PRODUK</th>
		          						<th scope="col" class="text-sm">EXPIRED DATE</th>
		          						<!-- <th scope="col" class="text-sm">HARGA BELI</th> -->
		          						<th scope="col" class="text-sm">JUMLAH</th>
		          						<!-- <th scope="col" class="text-sm">DISKON</th> -->
		          						<!-- <th scope="col" class="text-sm">TOTAL (RP)</th> -->
		          						<th scope="col" class="text-sm"></th>
		          					</tr>
		          				</thead>
		          				<tbody>
		          					<tr v-for="row in produk_dibeli" :key="row.id">
		          						<td>
		          							<input class="form-control" type="text" name="" disabled="" v-model="row.nama_produk">
		          						</td>
		          						<td>
		          							<input class="form-control" type="date" name="" disabled="" v-model="row.expired_date">
		          						</td>
		          						<!-- <td>
		          							<input class="form-control" type="number" name="" disabled="" v-model="row.harga_beli">
		          						</td> -->
		          						<td>
		          							<input class="form-control" type="number" name="" disabled="" v-model="row.jumlah">
		          						</td>
		          						<!-- <td>
		          							<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">{{ row.label_diskon }}</div>
							                  </div>
						                      <input type="number" class="form-control" step="any" v-model="row.diskon" disabled="">
							                </div>
		          						</td> -->
		          						<!-- <td>
		          							<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">Rp. </div>
							                  </div>
						                      <input type="number" class="form-control" step="any" v-model="row.total" disabled="">
							                </div>
		          						</td> -->
		          					</tr>

		          					<tr>
		          						<td>
		          							<multiselect style="min-width: 100px;" 
									          v-model="produk_select_value"
									          deselect-label="Can't remove this value"
									          track-by="nama"
									          label="nama"
									          placeholder="Pilih Produk"
									          :loading="loading_produk_select"
									          :options="produk"
									          :allow-empty="false"
									          @update:model-value="pilihProduk"
									        >
									        </multiselect>
		          							<input type="number" name="" class="form-control" min="0" style="visibility: hidden; height: 0px;">
		          						</td>
		          						<td>
		          							<input type="date" disabled="" name="" class="form-control" min="0" v-model="pembelian_baru.expired_date">
		          						</td>
		          						<!-- <td>
		          							<input type="number" name="" class="form-control" min="0" v-model="pembelian_baru.harga_beli">
		          						</td> -->
		          						<td>
		          							<input type="number" name="" class="form-control" min="1" v-model="pembelian_baru.jumlah" @keyup="hitungTotalPembelianBaru">
		          						</td>
		          						<!-- <td>
		          							<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">{{ pembelian_baru.label_diskon }}</div>
							                  </div>
						                      <input type="number" class="form-control" step="any" v-model="pembelian_baru.diskon" @keyup="hitungTotalPembelianBaru">
							                </div>
		          						</td> -->
		          						<!-- <td>
		          							<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">Rp. </div>
							                  </div>
						                      <input type="number" class="form-control" disabled="" v-model="pembelian_baru.total">
						                	</div>
		          						</td> -->
		          						<td><i @click="tambahPembelian" class="fas fa-plus-circle fa-2x text-secondary" style="cursor: pointer;"></i></td>
		          					</tr>
		          				</tbody>
		          			</table>

		          			<div class="row mt-2">
		          				<div class="col" v-if="cabang.jenis == 'pusat'">
		          					<label class="text-sm">Sub Total</label>
		          					<div class="input-group mb-3">
				                  <div class="input-group-prepend">
				                    <div class="btn btn-secondary">Rp. </div>
				                  </div>
				                  <!-- /btn-group -->
			                      <input type="number" class="form-control" disabled="" v-model="pembelian.sub_total">
				                </div>
		          				</div>
		          				<div class="col">
		          					<label class="text-sm">Diskon</label>
		          					<div class="input-group mb-3">
				                  <div class="input-group-prepend">
				                    <div class="btn btn-secondary">{{ pembelian.label_diskon }}</div>
				                  </div>
				                  <!-- /btn-group -->
			                      <input type="number" class="form-control" step="any" v-model="pembelian.diskon" @keyup="hitungTotalPembelian">
				                </div>
		          				</div>
		          				<div class="col">
		          					<label class="text-sm">PPN</label>
		          					<div class="input-group mb-3">
				                  <div class="input-group-prepend">
				                    <div class="btn btn-secondary">{{ pembelian.label_ppn }}</div>
				                  </div>
				                  <!-- /btn-group -->
			                      <input type="number" class="form-control" step="any" v-model="pembelian.ppn" @keyup="hitungTotalPembelian">
				                </div>
		          				</div>
		          				<div class="col">
		          					<label class="text-sm">Ongkos Kirim</label>
		          					<div class="input-group mb-3">
				                  <div class="input-group-prepend">
				                    <div class="btn btn-secondary">Rp. </div>
				                  </div>
				                  <!-- /btn-group -->
			                      <input type="number" class="form-control" step="any" v-model="pembelian.ongkos_kirim" @keyup="hitungTotalPembelian">
				                </div>
		          				</div>
		          			</div>

		          			<div class="row" v-if="cabang.jenis == 'pusat'">
		          				<div class="col col-lg-3">
		          					<label class="text-sm">Total</label>
		          					<div class="input-group mb-3">
				                  <div class="input-group-prepend">
				                    <div class="btn btn-secondary">Rp. </div>
				                  </div>
				                  <!-- /btn-group -->
			                      <input type="number" class="form-control" step="any" v-model="pembelian.total" disabled="">
				                </div>
		          				</div>
		          			</div>

		          			<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
								<div>Submit</div>	
								<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
								  <span class="sr-only">Loading...</span>
								</div>
							</button>
	          			</div>
	          		</div>
          		</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import Multiselect from '@suadelabs/vue3-multiselect'

	import { onMounted, ref, computed, reactive } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'

	const user 		  = computed(() => store.getters['auth/user'])
	const cabangLogin = computed(() => store.getters['auth/cabang'])
	const pembelian = reactive({
		"pembeli_id" : "",
		"nama_pembeli" : "",
		"penjual_id" : "",
		"nama_penjual" : "",
		"nomor_faktur" : "",
		"produk" : [],
		"sub_total" : "",
		"label_diskon" : "...",
		"diskon" : "",
		"label_ppn" : "...",
		"ppn" : "",
		"ongkos_kirim" : "",
		"total" : ""
	})
	const produk_dibeli = ref([])
	const pembelian_baru = reactive({
		"produk_id" : "",
		"nama_produk" : "",
		"expired_date" : "",
		"harga_beli" : "",
		"jumlah" : "",
		"label_diskon" : "...",
		"diskon" : "",
		"total" : ""
	})
	const cabang = ref([])
	const produk = ref([])
	const produk_select_value = ref('')
	const cabang_select_value = ref('')
	const loading_cabang_select = ref(true)
	const loading_produk_select = ref(false)
	const loading = ref(false)

	const getCabang = async () => {
		let { data } = await axios.get(`api/cabang/${cabangLogin.value.tipe}`)
		
		if (data == 'kosong') {
			loading_cabang_select.value = false
			cabang.value = []
		} else {
			loading_cabang_select.value = false
			cabang.value = data
		}
	}

	const getPembeli = async () => {
		let { data } = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

		pembelian.pembeli_id = data.id
		pembelian.nama_pembeli = data.nama
	}

	const pilihCabang = (value) => {
		produk_select_value.value = ''
		pembelian_baru.nama_produk = ''
		pembelian_baru.harga_beli = ''
		pembelian_baru.expired_date = ''
		pembelian_baru.jumlah = ''
		pembelian_baru.diskon = ''
		pembelian_baru.total = ''

		pembelian.penjual_id = value.id

		for (var i = 0; i < cabang.value.length; i++) {
			if (cabang.value[i].id == value.id) {
				pembelian.nama_penjual = cabang.value[i].nama
			}
		}

		getProduk(value.id)
	}

	const getProduk = async (id) => {
		produk.value = []
		loading_produk_select.value = true
		let { data } = await axios.get(`api/stok_produk/getStokProduk/${id}`)
		
		if (data == 'kosong') {
			loading_produk_select.value = false
			produk.value = []
		} else {
			loading_produk_select.value = false
			produk.value = data
		}
	}

	// PEMBELIAN BARU
	const pilihProduk = () => {
		pembelian_baru.produk_id = produk_select_value.value.produk_id
		
		for (var i = 0; i < produk.value.length; i++) {
			if (produk.value[i].produk_id == pembelian_baru.produk_id) {
				pembelian_baru.nama_produk = produk.value[i].nama
				pembelian_baru.harga_beli = produk.value[i].produk.harga_jual
				pembelian_baru.expired_date = produk.value[i].expired_date
				pembelian_baru.jumlah = 1
				pembelian_baru.total = pembelian_baru.harga_beli * pembelian_baru.jumlah
			}
		}
		hitungTotalPembelianBaru()
	}

	const hitungTotalPembelianBaru = () => {

		pembelian_baru.total = pembelian_baru.harga_beli * pembelian_baru.jumlah

		if (pembelian_baru.diskon != '' && pembelian_baru.diskon > 0) {
			if (pembelian_baru.diskon <= 100) {
				pembelian_baru.label_diskon = 'Persen (%)'
				let diskon = (pembelian_baru.total * pembelian_baru.diskon) / 100
				pembelian_baru.total = pembelian_baru.total - diskon
			} else {
				pembelian_baru.label_diskon = 'Rp. '
				let diskon = pembelian_baru.diskon
				pembelian_baru.total = pembelian_baru.total - diskon
			}
		} else {
				pembelian_baru.label_diskon = '...'
		}
	}

	const tambahPembelian = () => {
		let label = '...'
		produk_select_value.value = ''

		if (pembelian_baru.diskon != '') {
			if (pembelian_baru.diskon <= 100) {
				label = 'Persen (%)'
			} else {
				label = 'Rp. '
			}
		} 

		produk_dibeli.value.push({
			"produk_id" : pembelian_baru.produk_id,
			"nama_produk" : pembelian_baru.nama_produk,
			"harga_beli" : pembelian_baru.harga_beli,
			"expired_date" : pembelian_baru.expired_date,
			"jumlah" : pembelian_baru.jumlah,
			"label_diskon" : label,
			"diskon" : pembelian_baru.diskon,
			"total" : pembelian_baru.total
		})

		pembelian_baru.produk_id = ''
		pembelian_baru.nama_produk = ''
		pembelian_baru.harga_beli = ''
		pembelian_baru.expired_date = ''
		pembelian_baru.jumlah = ''
		pembelian_baru.label_diskon = '...'
		pembelian_baru.diskon = ''
		pembelian_baru.total = ''

		pembelian.sub_total = 0
		pembelian.total = 0
		for (var i = 0; i < produk_dibeli.value.length; i++) {
			pembelian.sub_total = pembelian.sub_total + produk_dibeli.value[i].total
			pembelian.total = pembelian.sub_total
		}
	}
	// END PEMBELIAN BARU

	const hitungTotalPembelian = () => {
		pembelian.total = pembelian.sub_total

		if (pembelian.diskon != '') {
			if (pembelian.diskon <= 100) {
				pembelian.label_diskon = 'Persen (%)'
				let diskon = (pembelian.sub_total * pembelian.diskon) / 100
				pembelian.total = pembelian.total - diskon
			} else {
				pembelian.label_diskon = 'Rp. '
				let diskon = pembelian.diskon
				pembelian.total = pembelian.total - diskon
			}
		} else {
			pembelian.label_diskon = '...'
		}

		if (pembelian.ppn != '') {
			if (pembelian.ppn <= 100) {
				pembelian.label_ppn = 'Persen (%)'
				let ppn = (pembelian.sub_total * pembelian.ppn) / 100
				pembelian.total = pembelian.total + ppn
			} else {
				pembelian.label_ppn = 'Rp. '
				let ppn = pembelian.ppn
				pembelian.total = pembelian.total + ppn
			}
		} else {
			pembelian.label_ppn = '...'
		}

		if (pembelian.ongkos_kirim != '') {
			pembelian.total = pembelian.total + parseInt(pembelian.ongkos_kirim)
		}
	}

	const prosesPembelian = () => {
		loading.value = true
		pembelian.produk = produk_dibeli.value
		
		axios.post('api/pembelian/create', pembelian)
		.then((response) => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil Menambahkan Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})
			loading.value = false
			router.push('/pemesanan-obat')
			console.log(response)
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal menambahkan data',
				icon: 'error',
				confirmButtonText: 'Ok'
			})	
			loading.value = false
			console.log(error)
		})
	}


	onMounted(() => {
		getCabang()
		getPembeli()
	})
</script>