<template>
	<TambahPemesananObatKecantikan v-if="cabang.tipe == 'kecantikan'" />	
	<TambahPemesananObatKesehatan v-else />	
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'

import TambahPemesananObatKesehatan from './components/TambahPemesananObatKesehatan.vue'
import TambahPemesananObatKecantikan from './components/TambahPemesananObatKecantikan.vue'

const cabang = computed(() => store.getters['auth/cabang'])
</script>